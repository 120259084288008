import React, { useEffect } from "react"

import "./waypointer.styl"

const isElementinViewport = el => {
  if (!el) {
    return false
  }

  var rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export default ({ waypoints = [] }) => {
  const idPrefix = "waypoint_"
  const points = waypoints.filter(({id}) => id)

  const checkWaypointsInView = () => {
    points.forEach(({ id }) => {
      const element = document.getElementById(id)
      const waypoint = document.getElementById(idPrefix + id)

      if (isElementinViewport(element)) {
        waypoint.classList.add("active")
      } else {
        waypoint.classList.remove("active")
      }
    })
  }

  useEffect(() => {
    checkWaypointsInView()
    window.addEventListener("scroll", checkWaypointsInView)
    return () => window.removeEventListener("scroll", checkWaypointsInView)
  })

  return (
    <ul id="waypoints">
      {points
        .map(({ id, text, onClick = null }) => (
          <li
            key={id}
            id={idPrefix + id}
            className="waypoint"
            onClick={() =>
              onClick
                ? onClick()
                : document.getElementById(id) &&
                  document
                    .getElementById(id)
                    .scrollIntoView({ behavior: "smooth" })
            }
          >
            {text}
          </li>
        ))}
    </ul>
  )
}
